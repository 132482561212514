<template>
<div>
    <b-card class="m-0" no-body>
        <div slot="header">
            <h3>{{translate('groups')}}</h3>
        </div>
        <search-wrapper-card :active="group_active" :activeStatus="activeStatus" :inactive="group_inactive" :inactiveStatus="inactiveStatus" form_type="groups">
            <q-input slot="search_input" bottom-slots @input="isTyping = true" v-model="searchQueryGroups" id="filternamegroup" class="search-input" type="text" :placeholder="translate('find_group')" :dense="true">
                <template v-slot:prepend>
                    <q-icon name="search" />
                </template>
            </q-input>
            <template slot="add_button">
                <q-btn no-caps v-if="!$q.screen.lt.sm" color="primary" @click.prevent="addGroupPanel">
                    <i class="fa fa-plus mr-1"></i> {{ translate('add_group') }}
                </q-btn>
                <q-btn dense round v-if="$q.screen.lt.sm" class="bg-white text-primary" icon="add" @click.prevent="addGroupPanel" />
            </template>
        </search-wrapper-card>
    </b-card>
    <div class="list-group wrapper_groups-list">
        <q-item v-for="(group, i) in groups" :key="'group-item-' + i + '-' + (i.id,group.id)" :id="group.id" class="list-item full-width" 
            :class="!group.is_approved ? 'needs-approval' : (group.is_active ? 'group-approved' : 'group-inactive')">
            <q-item-section class="p-0 pl-md-2" side>
                <div class="image-thumbnail cursor-pointer" @click.prevent="!group.is_approved ? showGroupInfoPanel(group, group.is_approved) : showEditGroupPanel(group.id, group.is_active, group.name)">
                    <i class="fa fa-photo fa-2x"></i>
                </div>
            </q-item-section>
            <q-item-section class="full-height align-self-center pt-2 pb-2">
                <strong class="text-muted">{{ group.name }}</strong>
            </q-item-section>
            <!-- [+] Desktop sections. -->
            <template v-if="!$q.screen.lt.sm">
                <template v-if="group.is_approved">
                    <q-item-section side class="full-height align-self-center pt-2 pb-2">
                        <q-btn round icon="fas fa-users" color="blue-6" @click="showGroupMembersPanel(group.id, group.name)">
                            <q-tooltip :offset="[10, 10]">{{ translate('group_members') }}</q-tooltip>
                        </q-btn>
                    </q-item-section>
                    <q-item-section side class="full-height align-self-center pt-2 pb-2">
                        <q-btn round icon="edit" color="primary" @click="showEditGroupPanel(group.id, group.is_active, group.name)">
                            <q-tooltip :offset="[10, 10]">{{ translate('edit_' + entity_type) }}</q-tooltip>
                        </q-btn>
                    </q-item-section>
                    <q-item-section side class="full-height align-self-center pt-2 pb-2">
                        <q-btn round icon="delete" color="danger" @click="showDeleteGroupPanel(group.id)">
                            <q-tooltip :offset="[10, 10]">{{ translate('delete_' + entity_type) }}</q-tooltip>
                        </q-btn>
                    </q-item-section>
                    <q-item-section side class="full-height align-self-center pt-2 pb-2">
                        <toggle-active :is_active="group.is_active" :no_label="true" :patch_toggle_route="patch_toggle_route" :item_id="group.id" :entity_type="entity_type" />
                    </q-item-section>
                </template>
                <template v-else>
                    <q-item-section side class="full-height align-self-center pt-2 pb-2">
                        <q-btn round icon="delete" color="danger" @click="showDeleteGroupPanel(group.id)">
                            <q-tooltip :offset="[10, 10]">{{ translate('delete_' + entity_type) }}</q-tooltip>
                        </q-btn>
                    </q-item-section>
                    <q-item-section side class="full-height align-self-center pt-2 pb-2">
                        <q-btn round icon="check" color="success" @click="ApproveGroup(group.id)">
                            <q-tooltip :offset="[10, 10]">{{ translate('approve') }}</q-tooltip>
                        </q-btn>
                    </q-item-section>
                </template>
            </template>
            <!-- [-] Desktop sections. -->
        </q-item>
    </div>
    <infinite-loading slot="append" :identifier="'groups-' + infGroups" @infinite="getGroups" />
</div>
</template>

<script>
import _ from 'lodash'
import { eventBus } from '../../../main'
import SearchWrapperCard from '../../template-parts/SearchWrapperCard'
import GroupMembers from './show/GroupMembers'
import EditGroup from './forms/EditGroup'
import AddGroup from './forms/AddGroup'
import DeleteGroup from './forms/DeleteGroup'
import ToggleActive from '../../template-parts/ToggleActive'
import GroupInfo from './show/GroupInfo'

export default {
    name: 'Group',
    components: {
        'search-wrapper-card': SearchWrapperCard,
        'toggle-active': ToggleActive,
    },
    data: function () {
        return {
            infGroups: +new Date(),
            show: true,
            group_active: 'active',
            group_inactive: 'inactive',
            groups: [],
            searchQueryGroups: "",
            isTyping: false,
            page: 1,
            state: {},
        }
    },
    computed: {
        entity_type: function () {
            return 'group'
        },
        patch_toggle_route: function () {
            return baseUrl + 'groups'
        },
    },
    mounted: function () {
        eventBus.$on('toggle_active_group', (item) => {
            this.toggleActiveGroup(item)
        })
        eventBus.$on('delete_group', (id) => {
            this.deleteGroup(id)
        })
    },
    watch: {
        searchQueryGroups: _.debounce(function () {
            this.isTyping = false
        }, 200),
        isTyping: function (value) {
            if (!value) {
                this.resetMainList()
            }
        }
    },
    methods: {
        updateIsApproved: function (id) {
            // Find index of specific object using findIndex method,
            var objIndex = this.groups.findIndex((obj => obj.id === id))
            // and update the element from the groups array.
            this.groups[objIndex]['is_approved'] = true
        },
        updateGroupName: function (item) {
            // Find index of specific object using findIndex method,
            var objIndex = this.groups.findIndex((obj => obj.id === item.group.id))
            // and update the element from the groups array.
            this.groups[objIndex]['name'] = item.group.name ? item.group.name : ''
        },
        toggleActiveGroup: function (item) {
            if (item.id !== null) {
                // Find index of specific object using findIndex method,
                var objIndex = this.groups.findIndex((obj => obj.id === item.id))
                if (this.groups[objIndex]) {
                    // And update is_active for the given item in list.
                    this.groups[objIndex]['is_active'] = item.is_active ? true : false
                    eventBus.$emit('update_toggle_' + this.entity_type, item)

                    if (item.is_active === 0 && this.group_active === 'active' && this.group_inactive === '') {
                        this.deleteGroup(item.id)
                    }
                    if (item.is_active === 1 && this.group_active === '' && this.group_inactive === 'inactive') {
                        this.deleteGroup(item.id)
                    }
                }
            }
        },
        deleteGroup: function (id) {
            // Find index of specific object using findIndex method,
            var objIndex = this.groups.findIndex((obj => obj.id === id))
            // and remove the element from the groups array.
            this.groups.splice(objIndex, 1)
        },
        showGroupInfoPanel: function (item, is_approved) {
            const panelInstance = this.$showPanel({
                component: GroupInfo,
                props: {
                    item: item,
                    is_approved: is_approved,
                    entity_type: this.entity_type,
                    patch_toggle_route: this.patch_toggle_route
                }
            })
        },
        resetMainList: function () {
            this.page = 1
            this.groups = []
            this.infGroups++
        },
        activeStatus: _.debounce(function () {
            if (this.group_active === 'active') {
                this.group_active = ''
            } else {
                this.group_active = 'active'
            }
            this.resetMainList()
        }, 200),
        inactiveStatus: _.debounce(function () {
            if (this.group_inactive === 'inactive') {
                this.group_inactive = ''
            } else {
                this.group_inactive = 'inactive'
            }
            this.resetMainList()
        }, 200),
        getGroups: function ($state) {
            var groupUrl = baseUrl +
                'groups?page=' + this.page +
                '&items_per_page=' + this.$items_per_page
            if (this.searchQueryGroups) {
                groupUrl += '&search=' + this.searchQueryGroups
            }
            if (this.group_active === 'active' && this.group_inactive === '') {
                groupUrl += '&group_status=' + this.group_active
            }
            if (this.group_active === '' && this.group_inactive === 'inactive') {
                groupUrl += '&group_status=' + this.group_inactive
            }

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            this.state = $state
            // To solve latency with server, push the items into a temporary scoped array and then into the main array.
            let groups = this.groups
            axios.get(groupUrl, {
                    headers: headers
                })
                .then(response => {
                    $state = this.state
                    if (response.data.items && response.data.items.length > 0) {                        
                        groups.push(...response.data.items)
                    }
                    if (response.data.next_page === true) {
                        this.page++
                        $state.loaded()
                    } else {
                        // To solve latency with server, push the items into a temporary scoped array and then into the main array.
                        this.groups = groups
                        $state.complete()
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        addGroupPanel() {
            const panelInstance = this.$showPanel({
                component: AddGroup,
            })

            panelInstance.promise.then(result => {
                if (result && result.status) {
                    this.resetMainList()
                }
            })
        },
        showDeleteGroupPanel(id) {
            const panelInstance = this.$showPanel({
                component: DeleteGroup,
                props: {
                    group_id: id
                }
            })
        },
        ApproveGroup(id) {
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            var data = {}
            axios.patch(baseUrl + 'groups/' + id + '/approve', data, {
                    headers: headers
                })
                .then(response => {
                    var key = response.data.key ? '_' + response.data.msg : ''
                    var message = 'approve_group_' + response.data.msg + key
                    var translated_message = this.translate(message)
                    
                    if (response.data.status) {
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                        // Update item in the main list as is_approved.
                        this.updateIsApproved(id)
                        eventBus.$emit('toggle_active_group', {
                            id: id,
                            is_active: 1
                        })
                    } else {
                        this.$toasted.error(translated_message, {
                            duration: this.$toasted_duration
                        })
                    }
                })
                .catch(function (error) {
                    this.$toasted.error(response.data.msg, {
                        duration: this.$toasted_duration
                    })
                })
        },
        showGroupMembersPanel(id, group_name) {
            const panelInstance = this.$showPanel({
                component: GroupMembers,
                props: {
                    id_group: id,
                    group_name: group_name
                }
            })
        },
        showEditGroupPanel(id, is_active, name) {
            const panelInstance = this.$showPanel({
                component: EditGroup,
                props: {
                    group_id: id,
                    entity_type: this.entity_type,
                    patch_toggle_route: this.patch_toggle_route,
                    is_active: is_active, 
                    group_name: name
                }
            })

            panelInstance.promise
                .then(result => {
                    if (result && result.status) {
                        this.updateGroupName(result)
                    }
                })
        }
    }
}
</script>

<style lang="scss" scoped>
.search-input {
    margin-left: -15px !important;
}

.q-item.list-item {
    background: white;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, .16);
    margin: 0 0 .5rem;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
    transition: border-color .1s ease-in-out;

    &:first-child {
        margin-top: .5rem;
    }

    &:hover {
        border-color: rgba(0, 0, 0, .26);
    }
}

.custom-control-input[indeterminate]~.custom-control-label::before {
    color: #fff;
    border-color: #20a8d8;
    background-color: #20a8d8;
}

.needs-approval {
    border-left: 2px solid var(--q-color-negative)!important;
}
.group-approved {
    border-left: 2px solid var(--q-color-primary)!important;
}
.group-inactive {
    border-left: 2px solid var(--q-color-dark)!important;
}
</style>
