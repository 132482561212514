<template>
<div class="fit">
    <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless>
        <b-tab class="p-0">
            <template slot="title">
                <template v-if="owner && (owner.name || owner.image_link)">
                    <span :class="$q.screen.lt.sm ? 'mr-2' : 'mr-3'"><i class="fas fa-crown text-muted mr-1"></i><template v-if="!$q.screen.lt.sm">{{ translate('group_owner') }}</template></span>
                        <q-chip class="p-0" text-color="primary">
                            <q-avatar class="shadow-2">
                                <img v-if="owner.image_link" :src="owner.image_link">
                                <i v-else class="fas fa-user fa-1x text-muted"></i>
                            </q-avatar>
                            {{ owner.name }}
                        </q-chip>
                </template>
                <template v-else>
                    <i class="fas fa-group" />
                </template>
            </template>
            <b-list-group class="list-group-accent">
                <b-list-group-item class="list-group-item-accent-secondary bg-light font-weight-bold text-uppercase cursor-pointer" @click.prevent="isHidden = !isHidden">
                    <q-icon :title="translate('Click to show or hide the members of the group')" name="list" />&nbsp;
                    <strong class="position-relative pr-4" style="display: inline-block; width: auto;">
                        {{translate('group_member')}}
                        <q-badge color="primary" floating>{{ members.length ? members.length : '0' }}</q-badge>
                    </strong>
                </b-list-group-item>
                <b-list-group-item v-if="!isHidden" class="list-group-item-accent-primary text-muted">
                    {{ translate('members_in_group') }}: {{ members.length ? members.length : '0' }}
                </b-list-group-item>
                <b-list-group-item v-if="!isHidden && members.length" class="list-group-item-accent-info wrapper_group-members-list">
                    <div>
                        <q-item class="full-width list-item" clickable ripple v-for="member in members" :key="member.id">
                            <q-item-section avatar class="p-0">
                                <q-avatar class="shadow-2">
                                    <img v-if="member.image_link" class="black-and-white" :src="member.image_link">
                                    <i v-else class="fas fa-user fa-1x text-muted"></i>
                                </q-avatar>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label>{{ member.name}}</q-item-label>
                            </q-item-section>
                            <q-item-section side>
                                <q-toggle @input="toggleActive(member.id, member.is_active)" v-model="member.is_active" checked-icon="check" color="green" unchecked-icon="clear" />
                            </q-item-section>
                        </q-item>
                    </div>
                    <div v-if="!members">
                        <q-item class="m-0 pt-3">{{ translate('No members yet') }}</q-item>
                    </div>
                </b-list-group-item>
            </b-list-group>
            <add-group-member :group_id="id_group" />
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
import { eventBus } from "../../../../main"
import AddMember from '../forms/AddGroupMember'
import DeleteMember from '../forms/DeleteGroupMember'

export default {
    name: 'GroupMembers',
    props: ['id_group', 'group_name'],
    components: {
        'add-group-member': AddMember
    },
    data: function () {
        return {
            owner: null,
            isHidden: true,
            members: [],
        }
    },
    created() {
        eventBus.$on('update_list', this.getGroupMembers)
        this.getGroupMembers()
    },
    methods: {
        toggleActive: function (member_id, is_active) {
            if (is_active) {
                is_active = 1
            }
            if (!is_active) {
                is_active = 0
            }
            var url = baseUrl + 'groups/members?group_id=' + this.id_group + '&customer_id=' + member_id + '&is_active=' + is_active

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken()
            }
            
            var data = {
                group_id: this.id_group,
                customer_id: member_id,
                is_active: this.is_active
            }
            axios.patch(url, data, {
                    headers: headers
                })
                .then(response => {
                    var message = is_active ? 'activated' : 'deactivated'
                    message = 'member_' + message
                    var translated_message = this.translate(message)
                    if (response.data.status) {
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                    } else {
                        this.$toasted.error('Something went wrong, please try again', {
                            duration: this.$toasted_duration
                        })
                    }
                })
                .catch(function (error) {
                    // console.log(error);
                });
        },
        ShowDeleteMemberPanel(id, member_name) {
            const panelInstance = this.$showPanel({
                component: DeleteMember,
                props: {
                    member_id: id,
                    group_id: this.id_group,
                    member_name: member_name,
                    group_name: this.group_name
                }
            })

            panelInstance.promise
                .then(result => {
                    if (result && result.status) {
                        eventBus.$emit('update_add_list')
                        this.getGroupMembers()
                    }
                })
        },
        getGroupMembers: function () {
            var groupUrl = baseUrl + 'groups/members?group_id=' + this.id_group

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            axios.get(groupUrl, {
                    headers: headers
                })
                .then(response => {
                    this.members = response.data.items.map(entity => {
                        let array = entity
                        array['image_link'] = entity.image_link ? baseUrl + entity.image_link : null
                        return array
                    })
                    this.owner = response.data.owner
                    this.owner.image_link = response.data.owner.image_link ? baseUrl + response.data.owner.image_link : null
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        closePanel() {
            this.$emit('closePanel')
        }
    }
}
</script>
<style lang="scss" scoped>
.q-item.list-item {
    background: white;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, .16);
    margin: .5rem 0;
    transition: border-color .1s ease-in-out;

    &:hover {
        border-color: rgba(0, 0, 0, .26);
        text-decoration: none;
        
        i {
            color: var(--q-color-negative)!important;
        }
        img.black-and-white {
            -webkit-filter: grayscale(0); /* Safari 6.0 - 9.0 */
            filter: grayscale(0);
        }
    }
}
</style>