<template>
    <b-list-group class="list-group-accent">
        <b-list-group-item class="list-group-item-accent-secondary bg-light font-weight-bold text-uppercase">
            <q-icon name="add" />&nbsp;
            {{translate('add_member')}}
        </b-list-group-item>
        <b-list-group-item class="list-group-item-accent-info list-group-item-divider">
            <q-input @input="isTypingGroupMember = true" v-model="searchQueryGroupMember" id="filternamecustomer" class="search-input" type="text" :placeholder="translate('find_client')" :dense="true">
                <template v-slot:prepend>
                    <q-icon name="search" />
                </template>
                <template v-slot:append>
                    <q-btn :size="$q.screen.lt.sm ? '0.65rem' : null" type="button" @click.stop="showAddClientPanel" flat dense color="dark" no-caps :label="translate('add_customer')" icon="add" />
                </template>
            </q-input>
            <div class="list_wrapper-group-members">
                <q-item tag="label" class="full-width list-item" clickable ripple v-for="customer in customers" :id="'gm-' + customer.id" :key="'gm-' + customer.id">
                    <q-item-section avatar class="p-0">
                        <q-avatar class="shadow-2">
                            <img v-if="customer.image_link" class="black-and-white" :src="customer.image_link">
                            <i v-else class="fas fa-user fa-1x text-muted"></i>
                        </q-avatar>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label>{{ customer.name }}</q-item-label>
                    </q-item-section>
                    <q-item-section side>
                        <q-btn icon="add" @click.prevent="registerGroupMember(customer.id)" dense flat color="primary" />
                    </q-item-section>
                </q-item>
            </div>
            <infinite-loading :identifier="'infiniteCustomer-' + infCustomer" slot="append" @infinite="getCustomer" />
        </b-list-group-item>
    </b-list-group>
</template>

<script>
import { eventBus } from "../../../../main"
import AddClient from '../../customers/forms/AddCustomer'

export default {
    name: 'AddGroupMember',
    props: ['group_id'],
    data() {
        return {
            infCustomer: +new Date(),
            member: {},
            customers: [],
            searchQueryGroupMember: '',
            isTypingGroupMember: false,
            customer_active: 'active',
            msg: null,
            page: 1,
            state: {},
        }
    },
    created() {
        eventBus.$on('update_add_list', this.resetMainList)
    },
    watch: {
        searchQueryGroupMember: _.debounce(function () {
            this.isTypingGroupMember = false
        }, 200),
        isTypingGroupMember: function (value) {
            if (!value) {
                this.resetMainList()
            }
        }
    },
    methods: {
        showAddClientPanel: function () {
            const panelInstance = this.$showPanel({
                component: AddClient
            })

            panelInstance.promise.then(result => {
                if (result && result.status) {
                    this.resetMainList()
                }
            })
        },
        resetMainList: function () {
            this.page = 1
            this.customers = []
            this.infCustomer++
        },
        registerGroupMember: function (customer_id) {
            var memberUrl = baseUrl + 'groups/members'
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }

            var data = {
                'group_id': this.group_id,
                'customer_id': customer_id
            }
            axios.post(memberUrl, data, {
                    headers: headers
                })
                .then(response => {
                    if (response.data.status) {
                        // Visually remove the added item from the list of available items.
                        var objIndex = this.customers.findIndex((obj => obj.id === customer_id))
                        this.customers.splice(objIndex, 1)
                        
                        eventBus.$emit('update_list')
                        this.$toasted.success('Your member is included', {
                            duration: this.$toasted_duration
                        })
                        this.$emit('closePanel', {
                            status: response.data.status
                        })
                    } else {
                        this.$toasted.error('The action couldn\'t be made', {
                            duration: this.$toasted_duration
                        })
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        getCustomer: function ($state) {
            this.state = $state
            var customerUrl = baseUrl +
                'groups/customers?page=' + this.page +
                '&items_per_page=' + this.$items_per_page +
                '&customer_status=' + this.customer_active +
                '&group_id=' + this.group_id
            if (this.searchQueryGroupMember !== '') {
                customerUrl = customerUrl + '&search=' + this.searchQueryGroupMember
            }

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }
            this.state = $state
            axios.get(customerUrl, {
                    headers: headers
                })
                .then(response => {
                    $state = this.state
                    if (response.data.items && response.data.items.length > 0) {
                        this.customers.push(...response.data.items.map(entity => {
                            let array = entity
                            array['image_link'] = entity.image_link ? baseUrl + entity.image_link : null
                            return array
                        }))
                    }
                    if (response.data.next_page === true) {
                        this.page++
                        $state.loaded()
                    } else {
                        $state.complete()
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        closePanel() {
            this.$emit('closePanel')
        }
    }
}
</script>
<style lang="scss" scoped>
.q-item.list-item {
    background: white;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, .16);
    margin: .5rem 0;
    transition: border-color .1s ease-in-out;

    &:hover {
        border-color: rgba(0, 0, 0, .26);
        text-decoration: none;
        
        i {
            color: var(--q-color-negative)!important;
        }
        img.black-and-white {
            -webkit-filter: grayscale(0); /* Safari 6.0 - 9.0 */
            filter: grayscale(0);
        }
    }
}
body .list_wrapper .q-item.row {
    width: 100%;
}
</style>