<template>
<div class="fit sticky-form-actions edit-panel">
    <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless :style="'overflow-y: auto; height: 100vh; padding-bottom: ' + formActionsRowHeight + 'px; '">
        <b-tab class="p-0">
            <template slot="title">
                <div :class="'pull-right'">
                    <q-btn class="mr-2" :label="!$q.screen.lt.sm ? translate('delete') : ''" no-caps dense color="danger" @click.prevent="showDeleteGroupPanel(group_id)" icon="delete" flat />
                    <toggle-active @closePanel="closePanel()" :no_label="$q.screen.lt.sm ? true : null" :is_active="is_active" :patch_toggle_route="patch_toggle_route" :item_id="group_id" :entity_type="entity_type" />
                </div>
                <div class="m-1"><q-icon name="edit" class="mr-1" />{{ translate('edit_group') }}</div>
            </template>
            <b-list-group class="list-group-accent">
                <b-form @submit.prevent>
                    <b-list-group-item class="p-0 pt-2">
                        <span class="ml-3 mr-3"><i class="fas fa-crown text-muted mr-1"></i> {{ translate('group_owner') }}</span>
                        <br v-if="$q.screen.lt.sm" />
                        <q-chip class="p-0" :class="$q.screen.lt.sm ? 'ml-3 mb-3' : ''" text-color="primary">
                            <q-avatar class="shadow-2">
                                <img v-if="group.owner_image_link" :src="group.owner_image_link">
                                <i v-else class="fas fa-user fa-1x text-muted"></i>
                            </q-avatar>
                            {{ group.owner_name }}
                        </q-chip>
                        <q-input dense square color="primary" type="text" id="name" v-model="group.name" :label="translate('group_name')" :placeholder="translate('name')" />
                    </b-list-group-item>
                    <b-list-group-item class="p-0">
                        <q-item @click="showGroupMembersPanel(group_id, group_name)" v-ripple clickable class="align-items-center full-width justify-items-spacebetween">
                            <q-item-label>
                                <strong>{{ translate('group_members') }}</strong>
                            </q-item-label>
                            <q-space class="pr-2" />
                            <q-item-section side class="pr-0">
                                <q-btn flat round class="shadow-2 m-0 p-1 bg-primary" dark>
                                    <i class="fas fa-2x fa-users"></i>
                                </q-btn>
                            </q-item-section>
                        </q-item>
                    </b-list-group-item>
                </b-form>
                <div class="form-actions bottom bg-light" :style="'width: ' + slideoutTabsWidth + 'px'">
                    <q-item class="p-3">
                        <q-item-section side>
                            <q-btn type="submit" no-caps color="info" @click.prevent="editGroup">
                                <q-icon size="1rem" name="check" class="mr-1" /> {{ translate('save') }}
                            </q-btn>
                        </q-item-section>
                        <q-item-section></q-item-section>
                        <q-item-section side>
                            <q-btn @click.prevent="closePanel" type="button" no-caps color="dark">
                                <q-icon size="1rem" name="close" class="mr-1" /> {{ translate('cancel') }}
                            </q-btn>
                        </q-item-section>
                    </q-item>
                </div>
            </b-list-group>
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
import _ from 'lodash'
import DeleteGroup from './DeleteGroup'
import ToggleActive from '../../../template-parts/ToggleActive'
import GroupMembers from '../show/GroupMembers'

export default {
    name: 'EditGroup',
    props: ['group_id', 'group_name', 'is_active', 'baseUrl', 'entity_type', 'patch_toggle_route'],
    components: {
        'toggle-active': ToggleActive,
    },
    data: function() {
        return {
            formActionsRowHeight: 0,
            slideoutTabsWidth: 0,

            group: {
                name: null,
            },
        }
    },
    created: function () {
        this.getGroup()
        this.getElementsWidthHeight()
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.getElementsWidthHeight)
    },
    mounted: function () {
        window.addEventListener('resize', this.getElementsWidthHeight)
    },
    methods: {
        getElementsWidthHeight: _.debounce(function () {
            this.formActionsRowHeight = document.querySelector('.form-actions').clientHeight
            this.slideoutTabsWidth = document.querySelector('.slideout .tabs').clientWidth
        }, 200),
        showGroupMembersPanel(id, group_name) {
            const panelInstance = this.$showPanel({
                component: GroupMembers,
                props: {
                    id_group: id,
                    group_name: group_name
                }
            })
        },
        showDeleteGroupPanel(id) {
            const panelInstance = this.$showPanel({
                component: DeleteGroup,
                props: {
                    group_id: id
                }
            })

            panelInstance.promise
                .then(result => {
                    if (result && result.status) {
                        this.$emit('closePanel', {
                            status: true
                        }) 
                        // Fix issue from slideout closing panel.
                        document.querySelector('body').classList.remove('slideout-panel-open')
                    }
                })
        },
        getGroup: function () {
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }

            axios.get(baseUrl + 'groups/' + this.group_id, {
                    headers: headers
                })
                .then(response => {
                    this.group = response.data.item
                    this.group['owner_image_link'] = response.data.item.owner_image_link ? baseUrl + response.data.item.owner_image_link : null
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        editGroup: function () {
            var group_name = this.group.name
            var group_id = this.group_id
            var data = {
                'name': this.group.name
            }

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }

            axios.put(baseUrl + 'groups/' + group_id, data, {
                    headers: headers
                })
                .then(response => {
                    var key = response.data.key ? '_' + response.data.key : ''
                    var message = 'edit_group_' + response.data.msg + key
                    var translated_message = this.translate(message)
                    if (response.data.status) {
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                        this.$emit('closePanel', {
                            status: true,
                            group: {id: group_id, name: group_name}
                        })                        
                    } else {
                        this.$toasted.error(translated_message, {
                            duration: this.$toasted_duration
                        })
                    }
                })
                .catch(error => {
                    // console.log(error)
                })
        },
        closePanel() {
            this.$emit('closePanel')
        }
    }
}
</script>
