<template>
<div>
    <b-card class="m-0" no-body>
        <div slot="header">
            <q-item class="full-width m-o p-0">
                <q-item-section side class="p-0 text-dark">
                    <h3>{{ translate('groups') }}</h3>
                </q-item-section>
                <q-space />
                <q-item-section side class="p-0">
                    <q-btn no-caps v-if="i_have_group" color="primary" class="disabled">
                        <q-tooltip :offset="[10, 10]">{{ translate('you_already_own_group') }}</q-tooltip>
                        <i class="fa fa-plus mr-1"></i> {{translate('request_new_group')}}
                    </q-btn>
                    <q-btn no-caps v-else color="primary" @click="RequestNewGroupPanel">
                        <i class="fa fa-plus mr-1"></i> {{translate('request_new_group')}}
                    </q-btn>
                </q-item-section>
            </q-item>
        </div>
    </b-card>
    <div class="list-group wrapper_cgroups-list">
        <q-item v-for="(group, i) in groups" :key="'group-item-' + i + '-' + (i.id,group.id)" :id="group.id" class="list-item full-width"  :class="group.is_owner === true ? 'is-owner-line' : ''">
            <q-item-section class="p-0 pl-md-2" side>
                <i v-if="group.is_owner === true" class="is-owner-indicator fas fa-crown"></i>
                <div class="image-thumbnail cursor-pointer" @click.prevent="group.is_approved ? showGroupMembersPanel(group.id, group.is_owner, group.name, group.is_active) : null">
                    <i class="fa fa-photo fa-2x"></i>
                </div>
            </q-item-section>
            <q-item-section class="full-height align-self-center pt-2 pb-2">
                <strong class="text-muted">{{ group.name }}</strong>
                <template v-if="!group.is_approved" class="full-height align-self-center pt-2 pb-2">
                    <small class="text-muted">{{ translate('your_group_request_is_waiting_for_approval') }}</small>
                </template>
            </q-item-section>
            <!-- [+] Desktop sections. -->
            <template v-if="!$q.screen.lt.sm && group.is_approved">
                <q-item-section side class="full-height align-self-center pt-2 pb-2">
                    <q-btn round icon="fas fa-users" color="blue-6" @click="showGroupMembersPanel(group.id, group.is_owner, group.name, group.is_active)">
                        <q-tooltip :offset="[10, 10]">{{ translate('group_members') }}</q-tooltip>
                    </q-btn>
                </q-item-section>
                <template v-if="group.is_owner === true">
                    <q-item-section side class="full-height align-self-center pt-2 pb-2">
                        <q-btn round icon="fa fa-user-plus" color="primary" @click="showInviteGroupMembersPanel(group.id)">
                            <q-tooltip :offset="[10, 10]">{{ translate('invite_member') }}</q-tooltip>
                        </q-btn>
                    </q-item-section>
                    <q-item-section side class="full-height align-self-center pt-2 pb-2">
                        <q-btn round icon="delete" color="danger" @click="showDeleteGroupPanel(group.id)">
                            <q-tooltip :offset="[10, 10]">{{ translate('delete_' + entity_type) }}</q-tooltip>
                        </q-btn>
                    </q-item-section>
                    <q-item-section side class="full-height align-self-center pt-2 pb-2">
                        <toggle-active :is_active="group.is_active" :no_label="true" :patch_toggle_route="patch_toggle_route" :item_id="group.id" :entity_type="entity_type" />
                    </q-item-section>
                </template>
            </template>
            <!-- [-] Desktop sections. -->
        </q-item>
    </div>
    <infinite-loading slot="append" :identifier="'customer-groups-' + infGroups" @infinite="getGroups" />
</div>
</template>

<script>
import { eventBus } from '../../../main'
import SearchWrapperCard from '../../template-parts/SearchWrapperCard'
import GroupMembers from './show/GroupMembersList'
import RequestNewGroup from './forms/RequestNewGroup'
import InviteGroupMember from './forms/InviteGroupMember'
import DeleteGroup from './forms/DeleteGroup'
import ToggleActive from '../../template-parts/ToggleActive'

import _ from 'lodash'
export default {
    name: 'GroupsCustomer',
    components: {
        'search-wrapper-card': SearchWrapperCard,
        'toggle-active': ToggleActive,
    },
    computed: {
        entity_type: function () {
            return 'group'
        },
        patch_toggle_route: function () {
            return baseUrl + 'groups'
        },
    },
    data: function () {
        return {
            i_have_group: false,
            infGroups: +new Date(),
            show: true,
            group_active: 'active',
            group_inactive: '',
            groups: [],
            searchQuery: "",
            isTyping: false,
            page: 1,
            state: {},
        }
    },
    mounted: function () {
        eventBus.$on('toggle_active_group', (item) => {
            this.toggleActiveGroup(item)
        })
        eventBus.$on('delete_group', (id) => {
            this.deleteGroup(id)
        })
    },
    watch: {
        searchQuery: _.debounce(function () {
            this.isTyping = false
        }, 200),
        isTyping: function (value) {
            if (!value) {
                this.resetMainList()
            }
        }
    },
    methods: {
        resetMainList: function () {
            this.page = 1
            this.groups = []
            this.infGroups++
        },
        toggleActiveGroup: function (item) {
            if (item.id !== null) {
                // Find index of specific object using findIndex method,
                var objIndex = this.groups.findIndex((obj => obj.id === item.id))
                if (this.groups[objIndex]) {
                    // And update is_active for the given item in list.
                    this.groups[objIndex]['is_active'] = item.is_active ? true : false
                    eventBus.$emit('update_toggle_' + this.entity_type, item)
                }
            }
        },
        deleteGroup: function (id) {
            // Find index of specific object using findIndex method,
            var objIndex = this.groups.findIndex((obj => obj.id === id))
            // and remove the element from the groups array.
            this.groups.splice(objIndex, 1)

            // Reset I have group.
            this.i_have_group = false
        },
        getGroups: function ($state) {
            var groupUrl = baseUrl +
                    'groups?page=' + this.page +
                    '&items_per_page=' + this.$items_per_page + 
                    (this.searchQuery ? '&search=' + this.searchQuery : '')
            if (this.group_active === 'active' && this.group_inactive === '') {
                groupUrl += '&group_status=' + this.group_active
            }
            if (this.group_active === '' && this.group_inactive === 'inactive') {
                groupUrl += '&group_status=' + this.group_inactive
            }

            this.state = $state
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            axios.get(groupUrl, {
                    headers: headers
                })
                .then(response => {
                    if (response.data.items) {
                        this.i_have_group = response.data.is_group_owner
                        this.groups.push(...response.data.items)
                    }
                    if (this.page <= response.data.total_pages) {
                        this.page++
                        this.state.loaded()
                    } else {
                        this.state.complete()
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        RequestNewGroupPanel() {
            const panelInstance = this.$showPanel({
                component: RequestNewGroup,
            })

            panelInstance.promise.then(result => {
                this.resetMainList()
            })
        },
        showDeleteGroupPanel(id) {
            const panelInstance = this.$showPanel({
                component: DeleteGroup,
                props: {
                    group_id: id
                }
            })
        },
        showGroupMembersPanel(id, is_owner, name, is_active) {
            const panelInstance = this.$showPanel({
                component: GroupMembers,
                props: {
                    id_group: id,
                    is_owner: is_owner,
                    patch_toggle_route: this.patch_toggle_route,
                    entity_type: this.entity_type,
                    group_name: name,
                    group_is_active: is_active,
                }
            })
        },
        showInviteGroupMembersPanel(id) {
            const panelInstance = this.$showPanel({
                component: InviteGroupMember,
                props: {
                    group_id: id
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.search-input {
    margin-left: -15px!important; 
    margin-right: -15px!important;
}
.is-owner-indicator {
    position: absolute;
    left: -8px;
    top: -8px;
    z-index: 2;
    pointer-events: none;
}
.is-owner-line {
    border-color: rgba(0, 0, 0, .25);
}
.q-item.list-item {
    background: white;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, .16);
    margin: 0 0 .5rem;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
    transition: border-color .1s ease-in-out;

    &:first-child {
        margin-top: .5rem;
    }

    &:hover {
        border-color: rgba(0, 0, 0, .26);
    }
}
</style>
