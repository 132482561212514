<template>
<div class="animated fadeIn">
    <groups-worker v-if="showGroupsWorker" :baseUrl="baseUrl"></groups-worker>
    <groups-customer v-if="showGroupsCustomer" :baseUrl="baseUrl"></groups-customer>
</div>
</template>

<script>
import _ from 'lodash'
import GroupsWorker from './GroupsWorker'
import GroupsCustomer from './GroupsCustomer';
export default {
    name: 'Groups',
    components: {
        'groups-worker': GroupsWorker,
        'groups-customer': GroupsCustomer
    },
    data: function () {
        return {
            baseUrl: baseUrl,
            enableCard: false,
        }
    },
    computed: {
        showGroupsWorker: function () {
            if (this.$appSettings.currentUserType() == 'admin') {
                return !this.enableCard;
            } else if (this.$appSettings.currentUserType() == 'worker') {
                return !this.enableCard;
            } else if (this.$appSettings.currentUserType() == 'customer') {
                return this.enableCard;
            }
        },
        showGroupsCustomer: function () {
            if (this.$appSettings.currentUserType() == 'admin') {
                return this.enableCard;
            } else if (this.$appSettings.currentUserType() == 'worker') {
                return this.enableCard;
            } else if (this.$appSettings.currentUserType() == 'customer') {
                return !this.enableCard;
            }
        }
    },
}
</script>
