<template>
<div class="fit sticky-form-actions">
    <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless :style="'overflow-y: auto; height: 100vh; padding-bottom: ' + formActionsRowHeight + 'px; '">
        <b-tab class="p-0">
            <template slot="title">
                <div class="mt-1"><i class="fas fa-paper-plane mr-1"></i> {{ translate('invite_member') }}</div>
            </template>
            <b-list-group class="list-group-accent">
                <b-form @submit.prevent>
                    <q-input color="primary" square dense :placeholder="translate('customer_email')" type="email" id="customer_email" v-model="customer.email" required />
                    <q-select color="primary" square dense v-model="customer.selected_language" :val="customer.selected_language" map-options :options="language_options" :label="translate('select_language')" />
                </b-form>
                <div class="form-actions bottom bg-light" :style="'width: ' + slideoutTabsWidth + 'px'">
                    <q-item class="p-3">
                        <q-item-section side :class="$q.screen.lt.sm ? 'pr-0' : ''">
                            <q-btn type="button" @click="inviteGroupMember" no-caps color="info">
                                <q-icon size="1rem" name="send" class="mr-1" /> {{ translate('send_invite') }}
                            </q-btn>
                        </q-item-section>
                        <q-item-section></q-item-section>
                        <q-item-section side>
                            <q-btn @click.prevent="closePanel" type="button" no-caps color="dark">
                                <q-icon size="1rem" name="close" class="mr-1" /> {{ translate('cancel') }}
                            </q-btn>
                        </q-item-section>
                    </q-item>
                </div>
            </b-list-group>
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
import _ from 'lodash'

export default {
    name: 'InviteGroupMember',
    props: ['group_id', 'members'],
    data: function () {
        // elena.coman33@gmail.com
        return {
            formActionsRowHeight: 0,
            slideoutTabsWidth: 0,

            member: {},
            customer: {
                email: null,
                selected_language: {value: this.$appSettings.lang ? this.$appSettings.lang : null, label: this.$appSettings.lang ? this.$appSettings.lang.toUpperCase() : null}
            },
            msg: null,
            page: 1,
            language_options: [{
                    value: 'en',
                    label: 'EN'
                },
                {
                    value: 'ro',
                    label: 'RO'
                }
            ]
        }
    },
    created: function () {
        this.getElementsWidthHeight()
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.getElementsWidthHeight)
    },
    mounted: function () {
        window.addEventListener('resize', this.getElementsWidthHeight)
    },
    methods: {
        getElementsWidthHeight: _.debounce(function () {
            this.formActionsRowHeight = document.querySelector('.form-actions').clientHeight
            this.slideoutTabsWidth = document.querySelector('.slideout .tabs').clientWidth
        }, 200),
        inviteGroupMember: function () {
            var memberUrl = baseUrl + 'groups/members/invite'
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }

            var data = {
                'group_id': this.group_id,
                'email': this.customer.email,
                'lang': this.customer.selected_language.value
            }
            axios.post(memberUrl, data, {
                    headers: headers
                })
                .then(response => {
                    this.msg = response.data.msg
                    var key = response.data.key ? '_' + response.data.key : ''
                    var message = 'invite_group_member_' + this.msg + key
                    var translated_message = this.translate(message)
                    if (response.data.status) {
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                        this.$emit('closePanel', {
                            status: response.data.status
                        })
                    } else {
                        this.$toasted.error(translated_message, {
                            duration: this.$toasted_duration
                        })
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        closePanel() {
            this.$emit('closePanel')
        }
    }
}
</script>
