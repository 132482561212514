<template>
<div class="fit sticky-form-actions" :class="is_owner ? 'edit-panel' : ''">
    <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless :style="'overflow-y: auto; height: 100vh; padding-bottom: ' + formActionsRowHeight + 'px; '">
        <b-tab class="p-0">
            <template v-if="is_owner" slot="title">
                <div class="mt-1 mb-1">
                    <div class="pull-right">
                        <q-btn no-caps dense color="danger" @click.prevent="showDeleteGroupPanel(id_group)" icon="delete" :label="!$q.screen.lt.sm ? translate('delete') : ''" flat />
                        <toggle-active :no_label="$q.screen.lt.sm ? true : null" @closePanel="closePanel()" :is_active="group_is_active" :patch_toggle_route="patch_toggle_route" :item_id="id_group" :entity_type="entity_type" />
                    </div>
                    <i class="fas fa-group mr-1"></i> {{ translate('group_member') }}: <span class="text-primary">{{ group_name }}</span>
                </div>
            </template>
            <template v-else slot="title">
                <div class="mt-1 mb-1">
                    <i class="fas fa-group mr-1"></i> {{ translate('group_member') }}: <span class="text-primary">{{ group_name }}</span>
                </div>
            </template>
            <b-list-group class="list-group-accent">
                <b-list-group-item class="text-muted">
                    {{ translate('members_in_group') }}: <strong>{{ members.length ? members.length : '0' }}</strong>
                    <div v-if="members.length" class="wrapper_group-members-list">
                        <q-item class="list-item" v-for="member in members" :key="member.id">
                            <q-item-section avatar class="p-0">
                                <q-avatar class="shadow-2">
                                    <img v-if="member.image_link" class="black-and-white" :src="member.image_link">
                                    <i v-else class="fas fa-user fa-1x text-muted"></i>
                                </q-avatar>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label>{{ member.name}}</q-item-label>
                            </q-item-section>
                            <q-item-section v-if="is_owner" side>
                                <q-toggle @input="toggleActive(member.id, member.is_active)" v-model="member.is_active" checked-icon="check" color="green" unchecked-icon="clear" />
                            </q-item-section>
                        </q-item>
                        <div v-if="!members">
                            <q-item class="m-0 pt-3">{{ translate('No members yet') }}</q-item>
                        </div>
                    </div>
                </b-list-group-item>
                <div v-if="is_owner">
                    <q-separator />
                    <q-item @click="showInviteGroupMembersPanel(id_group)" v-ripple clickable class="align-items-center full-width justify-items-spacebetween">
                        <q-item-label>
                            <strong>{{ translate('invite_member') }}</strong>
                        </q-item-label>
                        <q-space class="pr-2" />
                        <q-item-section side class="pr-0">
                            <q-btn flat round class="shadow-2 m-0 p-1 bg-primary" dark>
                                <i class="fas fa-2x fas fa-user-plus"></i>
                            </q-btn>
                        </q-item-section>
                    </q-item>
                    <q-separator />
                </div>
                <div class="form-actions bottom bg-light" :style="'width: ' + slideoutTabsWidth + 'px'">
                    <q-item class="p-3">
                        <q-item-section side>
                        </q-item-section>
                        <q-item-section></q-item-section>
                        <q-item-section side>
                            <q-btn @click.prevent="closePanel" type="button" no-caps color="dark">
                                <q-icon size="1rem" name="close" class="mr-1" /> {{ translate('cancel') }}
                            </q-btn>
                        </q-item-section>
                    </q-item>
                </div>
            </b-list-group>
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
import _ from 'lodash'
import DeleteMember from '../forms/DeleteGroupMember'
import DeleteGroup from '../forms/DeleteGroup'
import ToggleActive from '../../../template-parts/ToggleActive'
import InviteGroupMember from '../forms/InviteGroupMember'

export default {
    name: 'GroupMembers',
    props: ['id_group', 'group_name', 'group_is_active', 'is_owner', 'patch_toggle_route', 'entity_type'],
    components: {
        'toggle-active': ToggleActive,
    },
    data: function () {
        return {
            formActionsRowHeight: 0,
            slideoutTabsWidth: 0,

            members: [],
        };
    },
    created: function () {
        this.getGroupMembers()
        this.getElementsWidthHeight()
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.getElementsWidthHeight)
    },
    mounted: function () {
        window.addEventListener('resize', this.getElementsWidthHeight)
    },
    methods: {
        getElementsWidthHeight: _.debounce(function () {
            this.formActionsRowHeight = document.querySelector('.form-actions').clientHeight
            this.slideoutTabsWidth = document.querySelector('.slideout .tabs').clientWidth
        }, 200),
        toggleActive: function (member_id, is_active) {
            if (is_active) {
                is_active = 1
            }
            if (!is_active) {
                is_active = 0
            }
            var url = baseUrl + 'groups/members?group_id=' + this.id_group + '&customer_id=' + member_id + '&is_active=' + is_active

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken()
            }
            
            var data = {
                group_id: this.id_group,
                customer_id: member_id,
                is_active: this.is_active
            }
            axios.patch(url, data, {
                    headers: headers
                })
                .then(response => {
                    var message = is_active ? 'activated' : 'deactivated'
                    message = 'member_' + message
                    var translated_message = this.translate(message)
                    if (response.data.status) {
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                    } else {
                        this.$toasted.error('Something went wrong, please try again', {
                            duration: this.$toasted_duration
                        })
                    }
                })
                .catch(function (error) {
                    // console.log(error);
                });
        },
        ShowDeleteMemberPanel(id, member_name) {
            const panelInstance = this.$showPanel({
                component: DeleteMember,
                props: {
                    member_id: id,
                    group_id: this.id_group,
                    member_name: member_name,
                    group_name: this.group_name
                }
            });

            panelInstance.promise
                .then(result => {
                    return this.getGroupMembers()

                })
        },
        showDeleteGroupPanel(id) {
            const panelInstance = this.$showPanel({
                component: DeleteGroup,
                props: {
                    group_id: id
                }
            })

            panelInstance.promise
                .then(result => {
                    if (result && result.status) {
                        this.$emit('closePanel', {
                            status: true
                        }) 
                        // Fix issue from slideout closing panel.
                        document.querySelector('body').classList.remove('slideout-panel-open')
                    }
                })
        },
        showInviteGroupMembersPanel(id) {
            const panelInstance = this.$showPanel({
                component: InviteGroupMember,
                props: {
                    group_id: id
                }
            })

            panelInstance.promise
                .then(result => {
                    this.page = 1
                    this.groups = []
                    return this.getGroups(this.state)
                })
        },
        getGroupMembers: function () {
            var groupUrl = baseUrl + 'groups/members?group_id=' + this.id_group

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            };
            axios.get(groupUrl, {
                    headers: headers
                })
                .then(response => {
                    this.members = response.data.items.map(entity => {
                        let array = entity
                        array['image_link'] = entity.image_link ? baseUrl + entity.image_link : null
                        return array
                    })
                })
                .catch(function (error) {
                    // console.log(error)
                });
        },
        closePanel() {
            this.$emit('closePanel');
        }
    }
}
</script>

<style lang="scss" scoped>
.q-item.list-item {
    background: white;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, .16);
    margin: .5rem 0;
    transition: border-color .1s ease-in-out;

    &:hover {
        border-color: rgba(0, 0, 0, .26);
        text-decoration: none;
        
        i {
            color: var(--q-color-negative)!important;
        }
        img.black-and-white {
            -webkit-filter: grayscale(0); /* Safari 6.0 - 9.0 */
            filter: grayscale(0);
        }
    }
}
</style>
