<template>
<div class="fit edit-panel">
    <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless>
        <b-tab class="p-0">
            <template slot="title">
                <q-btn no-caps dense color="danger" @click.prevent="showDeleteGroupPanel(item.id)" icon="delete" :label="translate('delete')" flat />
                <q-btn no-caps dense color="success" @click.prevent="ApproveGroup(item.id)" icon="check" :label="translate('approve')" flat />
            </template>
            <b-list-group class="list-group-accent">
                <q-separator />
                <b-list-group-item class="list-group-item-accent-secondary bg-light font-weight-bold text-uppercase">
                    <q-icon name="fa fa-2x fa-user" />&nbsp;
                    {{ translate('group') }}
                </b-list-group-item>
                <q-separator />
                <b-list-group-item class="list-group-item-accent-info list-group-item-divider">
                    <q-item class="p-0 pl-1 pr-1">
                        <q-item-section>
                            <small>{{ translate('group') }}</small>
                            <strong>{{ item.name }}</strong> 
                        </q-item-section>
                    </q-item>
                    <q-item class="p-0 pl-1 pr-1">
                        <q-item-section>
                            <small>{{ translate('created_at') }}</small>
                            <strong>{{ user.created_at }}</strong>
                        </q-item-section>
                    </q-item>
                </b-list-group-item>
            </b-list-group>
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
import DeleteGroup from '../forms/DeleteGroup'

export default {
    name: 'GroupInfo',
    props: ['item', 'is_approved', 'entity_type', 'patch_toggle_route'],
    data: function () {
        return {
            user: {}
        }
    },
    created: function () {
        this.getGroup()
    },
    methods: {
        ApproveGroup: function (id) {
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            var data = {}
            axios.patch(baseUrl + 'groups/' + id + '/approve', data, {
                    headers: headers
                })
                .then(response => {
                    if (response.data.status) {
                        this.$toasted.success(response.data.msg, {
                            duration: this.$toasted_duration
                        })
                        this.resetMainList()
                    } else {
                        this.$toasted.error(response.data.msg, {
                            duration: this.$toasted_duration
                        })
                    }
                })
                .catch(function (error) {
                    this.$toasted.error(response.data.msg, {
                        duration: this.$toasted_duration
                    })
                })
        },
        getGroup: function ($state) {
            var url = baseUrl + 'groups/' + this.item.id
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    this.user = response.data.item
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        showDeleteGroupPanel: function (id) {
            const panelInstance = this.$showPanel({
                component: DeleteGroup,
                props: {
                    user_id: id
                }
            })

            panelInstance.promise
                .then(result => {
                    if (result && result.status) {
                        this.$emit('closePanel', {
                            status: true
                        })
                        // Fix issue from slideout closing panel.
                        document.querySelector('body').classList.remove('slideout-panel-open')
                    }
                })
        },
        closePanel: function () {
            this.$emit('closePanel')
        }
    },
}
</script>

