<template>
<div class="container">
    <br>
    <b-card>
        <div slot="header">
            <h3>
                <strong>{{translate('delete_member')}}</strong>
            </h3>
        </div>
        <p class="text-muted">
            <i class="fas fa-group margin-right" /><strong>{{ group_name }}</strong><br />
            <i class="fas fa-user ml-1 mr-2" /><strong>{{ member_name }}</strong>
        </p>
        <p><q-separator /></p>
        <p>{{translate('deleting_member')}}</p>
        <p><q-separator /></p>
        <b-row>
            <b-col>
                <b-btn variant="danger" @click="deleteMember">{{translate('yes')}}</b-btn>
            </b-col>
            <b-col class="text-right">
                <b-button @click.prevent="closePanel" type="button" variant="light">{{translate('no')}}</b-button>
            </b-col>
        </b-row>

    </b-card>
</div>
</template>

<script>
export default {
    name: 'GroupMember',
    props: ['member_id', 'group_id', 'member_name', 'group_name'],
    data() {
        return {
            msg: '',
        };
    },
    methods: {
        deleteMember: function () {
            var memberUrl = baseUrl + 'groups/members/' + this.group_id + '/' + this.member_id
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            };
            axios.delete(memberUrl, {
                    headers: headers
                })
                .then(response => {
                    if (response.data.status) {
                        this.$toasted.success('Your member is deleted', {
                            duration: this.$toasted_duration
                        })
                        this.$emit('closePanel', {
                            status: response.data.status
                        });
                    } else {
                        this.$toasted.error('The data was not updated', {
                            duration: this.$toasted_duration
                        })
                    }
                })
                .catch(function (error) {
                    // console.log(error);
                });
        },
        closePanel() {
            this.$emit('closePanel');
        }
    }
}
</script>
<style scoped>
.fas.fa-group.margin-right {
    margin-right: 0.45rem;
}
</style>